<template>
  <span
    class="ui-inline-flex ui-justify-center ui-gap-3xs ui-items-center ui-py-3xs ui-px-[10px] ui-rounded-pill ui-cursor-pointer"
    :class="tagVariant[variant]"
  >
    <Icon name="times-thick" :color="iconColor[variant][0]" :size="10" />
    <span v-if="title" class="ui-ml-3xs" :class="titleFontWeight[variant]">
      {{ title }}
    </span>
    <span v-if="subtitle">{{ subtitle }}</span>
  </span>
</template>

<script lang="ts" setup>
import Icon from "../Icon/Icon.vue";
import type { TagProps } from "./types";
import { getColor } from "mkm-avengers";

withDefaults(defineProps<TagProps>(), {
  title: "",
  subtitle: "",
  variant: "applied",
});

const tagVariant = {
  ["applied"]:
    "ui-bg-white ui-border-sm ui-border-mkm-blue-light ui-box-border ui-text-charcoal-default ui-text-xs ui-leading-[18px] ui-font-semi hover:ui-bg-neutral-lighter active:ui-bg-neutral-light",
  ["clear"]:
    "ui-bg-mkm-blue-light ui-border-sm ui-border-mkm-blue-light ui-text-white ui-text-xs ui-leading-[18px] ui-font-semi hover:ui-bg-mkm-blue-dark hover:ui-border-mkm-blue-dark",
};

const iconColor = {
  ["applied"]: getColor("mkm-blue-light"),
  ["clear"]: getColor("white"),
};

const titleFontWeight = {
  ["applied"]: "ui-font-bold",
  ["clear"]: "ui-font-semi",
};
</script>
