<template>
  <Stack
    tag="div"
    direction="col"
    gap="none"
    :justify="filters.length > 0 ? 'between' : 'end'"
    align="center"
    class="mb-xs"
  >
    <Text
      v-if="categoryBCData?.items?.length > 0"
      size="sm"
      align="center"
      class="w-fit self-center sm:hidden mb-3xs"
      data-test-id="plpProductsCountMobile"
      data-fs="plpProductsCountMobile"
    >
      {{ totalItems }}
    </Text>

    <Grid tag="div" class="!w-full grid mb-xs" justify="end" align="end">
      <GridItem col-span="2" :breakpoints="{}" v-if="categoryBCData.items.length > 0">
        <Button
          variant="secondary"
          size="md"
          class="my-[6px] !py-[12px] sm:!hidden !w-full md:!w-unset !justify-center"
          @click="setCategoryFilters"
          data-fs="plpMobileFilterButton"
          >Filters</Button
        >
      </GridItem>

      <GridItem
        col-span="3"
        col-start="4"
        :breakpoints="{}"
        class="!hidden md:!flex"
        :class="selectedBranch && !hasSpecificPrices ? 'lg:!col-start-7' : 'lg:!col-start-10'"
      >
        <Text
          v-if="categoryBCData.items.length > 0"
          size="sm"
          align="right"
          class="w-fit self-center"
          data-test-id="plpProductsCount"
          data-fs="plpProductsCount"
          >{{ totalItems }}</Text
        >
      </GridItem>

      <GridItem
        col-span="2"
        class="sm:!col-start-7 lg:!col-start-auto"
        :breakpoints="{ sm: '2', lg: '3' }"
        v-if="selectedBranch && !hasSpecificPrices && categoryBCData.items.length > 0"
      >
        <Select
          placeholder="Sort By"
          :options="sortChoices"
          class="!w-1/2"
          id="categorySort"
          label="sort category products"
          :label-visible="false"
          @update:model-value="(value) => updateSort(value)"
          data-test-id="plpSortProducts"
        />
      </GridItem>
    </Grid>

    <Stack tag="div" direction="col" class="!w-full relative" align="start">
      <CategoryAppliedFilters />
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
const { filters } = useFilters();
const { selectedBranch } = useBranches();
const { setCategoryFilters } = useUIState();
const { user } = useUser();
const route = useRoute();
const { itemsPerPage } = useCategoryPage();
const { data: categoryBCData } = useCategory();
const sortValue = ref<string | null>(route.query.sort_value?.toString() || null);

const emit = defineEmits<{
  (event: "sort-by", value: string): void;
}>();

const updateSort = (value: string) => {
  sortValue.value = value;
  emit("sort-by", value);
};

const sortChoices = reactive([
  { label: "Best match", value: "default" },
  { label: "Lowest price", value: "price_asc" },
  { label: "Highest price", value: "price_desc" },
]);

const hasSpecificPrices = computed(
  () => selectedBranch.value && user.value && selectedBranch?.value?.pricing?.terms !== user?.value?.account?.terms,
);
const currentPage = computed(() => Number(route.query.p || 1));

const totalItems = computed(() => {
  const maxPerPage = itemsPerPage.value;
  const totalItemsCount = categoryBCData.value.total;
  const startItem = (currentPage.value - 1) * maxPerPage + 1;
  const endItem = Math.min(startItem + maxPerPage - 1, totalItemsCount);

  return `Showing ${startItem} to ${endItem} of ${totalItemsCount} items`;
});
</script>
